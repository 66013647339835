<template>
  <ion-list-header color="light">
    <ion-row>
      <ion-text class="xLargeFont ion-align-self-center">
        Plagas Encontradas
      </ion-text>
    </ion-row>
  </ion-list-header>
  <ion-list
    lines="full"
    v-for="plaga in plagas"
    :value="plaga"
    :key="plaga._id"
  >
    <ion-item
      @click="goToPlaga(plaga)"
      button="true"
      type="button"
      class="itemBorder"
    >
      <ion-row>
        <ion-col class="ion-margin-bottom">
          <ion-thumbnail style="height:125%">
            <ion-img
              src="assets/icon/plagas/icoPlgMaleza.png"
              v-if="plaga.tipoPlaga === 'Maleza'"
            />
            <ion-img
              src="assets/icon/plagas/icoPlgInsecto.png"
              v-if="plaga.tipoPlaga === 'Insecto'"
            />
            <ion-img
              src="assets/icon/plagas/icoPlgEnferm.png"
              style="height:80%"
              v-if="plaga.tipoPlaga === 'Enfermedad'"
            />
          </ion-thumbnail>
        </ion-col>
        <ion-col size="auto" class="margen8pxArriba smallFont">
          <ion-text>
            {{ plaga.nombrePlaga }}
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-item>
  </ion-list>
  <ion-modal
    :is-open="modalPlaga"
    @didDismiss="modalPlaga = false"
    :backdropDismiss="false"
  >
    <FichaPlagaUnica
      :error="error"
      :plaga="selectedPlaga"
      :campaniaCultivo="campaniaCultivo"
      @close="closeModalPlaga"
    />
  </ion-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  IonImg,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonItem,
  IonModal,
  IonThumbnail,
  IonText,
  alertController
} from "@ionic/vue";
import { Actividades } from "@/api";
import { addOutline } from "ionicons/icons";
import FichaPlagaUnica from "./FichaPlagaUnica.vue";

export default {
  components: {
    IonItem,
    IonRow,
    IonCol,
    IonText,
    IonModal,
    IonThumbnail,
    IonList,
    IonListHeader,
    IonImg,
    FichaPlagaUnica
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) }
  },

  data: () => ({
    plagas: [],
    selectedPlaga: {},
    modalPlaga: false,
    icons: {
      add: addOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    idProductor() {
      return this.$route.params.idProductor;
    },
    idLote() {
      return this.$route.params.idLote;
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
  },

  watch: {
    async campaniaCultivo() {
      await this.dataSync();
    },
    async idLote() {
      await this.dataSync();
    },
    async idProductor() {
      await this.dataSync();
    }
  },

  methods: {
    ...mapActions(["getMe"]),

    async dataSync() {
      this.plagas = await this.getPlagas();
      this.sortPlagas();
    },

    goToPlaga(plaga) {
      this.selectedPlaga = plaga;
      this.modalPlaga = true;
    },

    closeModalPlaga() {
      this.modalPlaga = false;
      this.selectedPlaga = {};
    },

    sortPlagas() {
      this.plagas = this.plagas.sort((a, b) => {
        if (a.nombrePlaga.toLowerCase() < b.nombrePlaga.toLowerCase()) {
          return a.nombrePlaga
            .toLowerCase()
            .localeCompare(b.nombrePlaga.toLowerCase());
        }
        return a.tipoPlaga.localeCompare(b.tipoPlaga);
      });
    },

    removeDuplicatesFromArray(arr) {
      const unique = arr.filter((obj, index, self) => {
        return (
          self.findIndex(
            p =>
              p.tipoPlaga === obj.tipoPlaga && p.nombrePlaga === obj.nombrePlaga
          ) === index
        );
      });
      return unique;
    },

    async getPlagas() {
      const { data, error } = await Actividades.get({
        query: {
          campaniaCultivo: this.campaniaCultivo._id,
          tipo: "Pulverización"
        },
        populate: JSON.stringify([
          { path: "plagasPulverizacion" },
          {
            path: "plagasPulverizacion",
            populate: ["maleza", "insecto", "enfermedad", "productoPlaga"]
          }
        ])
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Actividades",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      let plagas = [];
      for (let a of data) {
        for (let p of a.plagasPulverizacion) {
          plagas.push(p);
        }
      }
      //console(test)
      plagas = plagas.map(p => {
        if (p.tipoPlaga === "Maleza") {
          return new Object({
            _id: p.maleza._id,
            tipoPlaga: p.tipoPlaga,
            nombrePlaga: p.maleza.nombre
          });
        }
        if (p.tipoPlaga === "Insecto") {
          return new Object({
            _id: p.insecto._id,
            tipoPlaga: p.tipoPlaga,
            nombrePlaga: p.insecto.nombre
          });
        }
        return new Object({
          _id: p.enfermedad._id,
          tipoPlaga: p.tipoPlaga,
          nombrePlaga: p.enfermedad.nombre
        });
      });
      return this.removeDuplicatesFromArray(plagas);
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

.whiteFontColor {
  --ion-color-constrast: #ffffff;
}

.negrita {
  font-size: 175%;
  margin-left: 0px;
  font-weight: bold;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.smallFont {
  font-size: small;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

.itemColor {
  --background: rgba(var(--ion-color-primary-rgb), 0.6);
  --border-color: rgba(var(--ion-color-primary-rgb), 0.6);
}

.margen8pxArriba {
  margin-top: 8px;
}
</style>
