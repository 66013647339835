<template>
  <ion-row>
    <ion-text class="xLargeFont ion-align-self-center">
      Adversidades
    </ion-text>
    <ion-fab-button
      class="ion-margin-left"
      size="small"
      @click="goToCreateAdversidad()"
      v-if="isEditable"
    >
      <ion-icon color="light" :md="icons.add" :ios="icons.add"></ion-icon>
    </ion-fab-button>
  </ion-row>
  <ion-button fill="clear" @click="changeSort()" v-if="adversidades.length">
    {{ "Ordenar por % de Daño " + flecha }}
  </ion-button>
  <ion-list
    lines="full"
    v-for="adversidad in adversidades"
    :key="adversidad._id"
  >
    <ion-item
      @click="goToEditAdversidad(adversidad)"
      button="true"
      type="button"
      class="itemBorder disabledOpacity"
      :disabled="!isEditable"
    >
      <ion-grid fixed>
        <ion-row class="ion-align-items-center">
          <ion-col class="smallFont">
            <ion-text style="white-space: normal">
              {{ adversidad.tipoAdversidad.nombre }}
            </ion-text>
          </ion-col>
          <ion-col class="smallFont" style="float:right">
            <ion-text>
              {{ formatDate(adversidad.fecha) }}
            </ion-text>
          </ion-col>
          <ion-col class="smallFont" style="float:right">
            <ion-text>
              {{ adversidad.porcEstimadoDanio + "%" }}
            </ion-text>
          </ion-col>
          <ion-col size="auto" v-if="isEditable">
            <ion-icon
              :md="icons.edit"
              :ios="icons.edit"
              color="dark"
            ></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  IonRow,
  IonCol,
  IonGrid,
  IonList,
  IonItem,
  IonIcon,
  IonButton,
  IonFabButton,
  IonText,
  alertController
} from "@ionic/vue";
import { Adversidades } from "@/api";
import { addOutline, pencilOutline } from "ionicons/icons";
import format from "@/utils/dateFormat";

export default {
  components: {
    IonItem,
    IonRow,
    IonCol,
    IonGrid,
    IonText,
    IonButton,
    IonFabButton,
    IonList,
    IonIcon
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) }
  },

  data: () => ({
    adversidades: [],
    sortBy: "",
    flecha: "",
    icons: {
      add: addOutline,
      edit: pencilOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    idProductor() {
      return this.$route.params.idProductor;
    },
    idLote() {
      return this.$route.params.idLote;
    },
    isEditable() {
      return this.campaniaCultivo &&
        (this.campaniaCultivo.abierto === true ||
          this.campaniaCultivo.abierto === false)
        ? this.campaniaCultivo.abierto
        : true;
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
    this.sortByTipoAdversidad();
  },

  watch: {
    async campaniaCultivo() {
      await this.dataSync();
    },
    async idLote() {
      await this.dataSync();
    },
    async idProductor() {
      await this.dataSync();
    },
    sortBy() {
      this.sortList();
    }
  },

  methods: {
    ...mapActions(["getMe"]),

    async dataSync() {
      this.adversidades = await this.getAdversidades();
    },

    formatDate(value) {
      if (!value) return "";
      return format(value, "dd/MM/yyyy");
    },

    sortList() {
      if (this.sortBy === "danioAsc") {
        this.sortByDanioAsc();
      } else if (this.sortBy === "danioDesc") {
        this.sortByDanioDesc();
      } else {
        this.sortByTipoAdversidad();
      }
    },

    changeSort() {
      if (this.sortBy === "danioAsc") {
        this.sortBy = "danioDesc";
        this.flecha = "(↓)";
      } else if (this.sortBy === "danioDesc" || this.sortBy === "") {
        this.sortBy = "danioAsc";
        this.flecha = "(↑)";
      }
    },

    goToCreateAdversidad() {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-createAdversidad",
          params: {
            idProductor: this.idProductor,
            idLote: this.campaniaCultivo.lote
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      } else {
        this.$router.push({
          name: "createAdversidad",
          params: {
            idLote: this.campaniaCultivo.lote
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      }
    },

    goToEditAdversidad(adversidad) {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-editAdversidad",
          params: {
            idProductor: this.idProductor,
            idLote: this.campaniaCultivo.lote,
            idAdversidad: adversidad._id
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      } else {
        this.$router.push({
          name: "editAdversidad",
          params: {
            idLote: this.campaniaCultivo.lote,
            idAdversidad: adversidad._id
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      }
    },

    sortByTipoAdversidad() {
      this.adversidades.sort((a, b) =>
        a.tipoAdversidad.nombre
          .toLowerCase()
          .localeCompare(b.tipoAdversidad.nombre.toLowerCase())
      );
    },

    sortByDanioAsc() {
      this.adversidades.sort(
        (a, b) => b.porcEstimadoDanio - a.porcEstimadoDanio
      );
    },

    sortByDanioDesc() {
      this.adversidades.sort(
        (a, b) => a.porcEstimadoDanio - b.porcEstimadoDanio
      );
    },

    async getAdversidades() {
      const { data, error } = await Adversidades.get({
        query: { campaniaCultivo: this.campaniaCultivo._id },
        populate: "adversidad, tipoAdversidad",
        sort: { fecha: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo adversidades",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

.whiteFontColor {
  --ion-color-constrast: #ffffff;
}

.negrita {
  font-size: 175%;
  margin-left: 0px;
  font-weight: bold;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.smallFont {
  font-size: small;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

.itemColor {
  --background: rgba(var(--ion-color-primary-rgb), 0.6);
  --border-color: rgba(var(--ion-color-primary-rgb), 0.6);
}

.margen8pxArriba {
  margin-top: 8px;
}

.disabledOpacity {
  opacity: 1 !important;
}
</style>
