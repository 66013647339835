<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <div id="container">
        <ion-item id="item" class="ion-margin-top margen9pxLados itemColor">
          <ion-grid fixed>
            <ion-row>
              <ion-col>
                <ion-row class="ion-align-items-end ion-justify-content-start">
                  <ion-col>
                    <ion-text color="dark">
                      Lote
                    </ion-text>
                  </ion-col>
                  <ion-col size="auto">
                    <ion-button
                      fill="clear"
                      @click="editLote"
                      class="ion-float-end"
                      size="small"
                    >
                      <ion-icon
                        :md="icons.edit"
                        :ios="icons.edit"
                        color="dark"
                      ></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
                <ion-text
                  class="ion-text-capitalize negrita"
                  style="white-space: normal"
                  color="dark"
                >
                  {{ nombreLoteCiudad }}
                </ion-text>
                <br />
                <ion-text color="secondary">
                  <i>{{ "Sup. " + hectareasLote + " Ha" }}</i>
                </ion-text>
                <br />
                <ion-text color="dark">
                  Establecimiento:
                </ion-text>
                <ion-text
                  class="ion-text-capitalize"
                  style="white-space: normal"
                  color="dark"
                >
                  <b>
                    {{ nombreEstablecimientoLote }}
                  </b>
                </ion-text>
                <ion-row
                  style="padding-top: 0px; padding-bottom: 0px;"
                  class="ion-align-items-center"
                >
                  <ion-col style="padding-top: 0px; padding-bottom: 0px;">
                    <ion-button fill="clear" @click="verMas = !verMas">
                      <ion-text color="tertiary" style="font-size: 90%">
                        Ver más
                      </ion-text>
                      <ion-icon
                        style="margin-left: 0.5%; padding-bottom:3%"
                        :md="icons.caretDown"
                        :ios="icons.caretDown"
                        color="dark"
                      ></ion-icon>
                    </ion-button>
                  </ion-col>
                  <ion-col
                    size="auto"
                    style="padding-top: 0px; padding-bottom: 0px;"
                  >
                    <ion-button
                      fill="clear"
                      size="small"
                      color="danger"
                      @click="deleteLote"
                    >
                      <ion-icon
                        :md="icons.trash"
                        :ios="icons.trash"
                        size="large"
                      ></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
                <div v-show="verMas">
                  <ion-text style="margin-left:5%; font-size: 90%;">
                    Tenencia:
                  </ion-text>
                  <ion-text style="font-size: 90%">
                    {{ lote.tenencia ? lote.tenencia : "" }}
                  </ion-text>
                  <br />
                  <ion-text style="margin-left:5%; font-size: 90%;">
                    Clase de Suelo:
                  </ion-text>
                  <ion-text style="font-size: 90%">
                    {{ lote.claseSuelo ? lote.claseSuelo : "" }}
                  </ion-text>
                  <br />
                  <ion-text style="margin-left:5%; font-size: 90%;">
                    Calidad del Lote:
                  </ion-text>
                  <ion-text style="font-size: 90%">
                    {{ lote.calidadLote ? lote.calidadLote : "" }}
                  </ion-text>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <div class="ion-margin-top" v-if="!hideCampaniaDiv">
          <ion-text class="margen8px" style="font-size:x-large">
            No hay ninguna campaña seleccionada
          </ion-text>
          <ion-row class="ion-float-right">
            <ion-button
              @click="volver"
              class="ion-margin-top ion-float-end margen9pxLados whiteFontColor"
              color="medium"
              fill="outline"
            >
              Volver
            </ion-button>
            <ion-button
              @click="modalEditCampania = true"
              class="ion-margin-top ion-float-end margen9pxLados"
              color="primary"
            >
              Crear Campaña
            </ion-button>
          </ion-row>
        </div>

        <div class="margen8px" v-if="hideCampaniaDiv">
          <ion-grid fixed>
            <ion-row class="ion-align-items-center">
              <ion-col size="3" style="margin-top:4px">
                <ion-text class="ion-float-right">Campaña:</ion-text>
              </ion-col>
              <ion-col>
                <ion-text
                  style="white-space: normal"
                  class="xLargeFont"
                  :color="campania.esActual ? 'primary' : 'tertiary'"
                >
                  {{ " " + nombreCampania || "" }}
                </ion-text>
              </ion-col>
              <ion-col size="auto">
                <ion-button
                  fill="clear"
                  @click="goToCampanias"
                  class="ion-float-end"
                  size="default"
                >
                  <ion-icon
                    :md="icons.reload"
                    :ios="icons.reload"
                    color="dark"
                  ></ion-icon>
                </ion-button>
              </ion-col>
              <br />
            </ion-row>
            <ion-row class="ion-align-items-center">
              <ion-col size="3" style="margin-top:4px">
                <ion-text class="ion-float-right">Cultivo:</ion-text>
              </ion-col>
              <ion-col>
                <ion-text
                  class="xLargeFont"
                  style="white-space: normal"
                  color="primary"
                >
                  {{ " " + cultivoVariedadCampania || "" }}
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-card color="light">
            <ion-card-content color="light">
              <ion-tabs>
                <ion-tab-bar slot="top" :selected-tab="tabOption">
                  <ion-tab-button
                    @click="tabOption = 'Actividades'"
                    tab="Actividades"
                  >
                    <ion-img
                      src="assets/icon/fichaLote/icoActividad.png"
                      style="height:70%"
                    />
                    <ion-label>Actividades</ion-label>
                  </ion-tab-button>
                  <ion-tab-button @click="tabOption = 'General'" tab="General">
                    <ion-img
                      src="assets/icon/fichaLote/icoGeneral.png"
                      style="height:70%"
                    />
                    <ion-label>Suelo</ion-label>
                  </ion-tab-button>
                  <ion-tab-button @click="tabOption = 'Plagas'" tab="Plagas">
                    <ion-img
                      src="assets/icon/fichaLote/icoPlagas.png"
                      style="height:70%"
                    />
                    <ion-label>Plagas</ion-label>
                  </ion-tab-button>
                  <ion-tab-button
                    @click="tabOption = 'Adversidades'"
                    tab="Adversidades"
                  >
                    <ion-img
                      src="assets/icon/fichaLote/icoAdversidad.png"
                      style="height:70%"
                    />
                    <ion-label>Adversidades</ion-label>
                  </ion-tab-button>
                  <!--                   <ion-tab-button @click="tabOption = 'Lluvia'" tab="Lluvia">
                    <ion-img
                      src="assets/icon/fichaLote/icoRiego.png"
                      style="height:70%"
                    />
                    <ion-label>Lluvia</ion-label>
                  </ion-tab-button> -->
                  <!--                   <ion-tab-button @click="tabOption = 'Rinde'" tab="Rinde">
                    <ion-img
                      src="assets/icon/fichaLote/icoRinde.png"
                      style="height:70%"
                    />
                    <ion-label>Rinde</ion-label>
                  </ion-tab-button> -->
                </ion-tab-bar>
              </ion-tabs>

              <br />
              <br />

              <div v-if="tabOption === 'General'">
                <FichaGeneralCampCultivo
                  :error="error"
                  :campaniaCultivo="campaniaCultivo"
                  @submitAgua="submitEditAgua"
                  @submitSuelo="submitEditSuelo"
                />
              </div>

              <div v-if="tabOption === 'Actividades'">
                <FichaActividades
                  :error="error"
                  :campaniaCultivo="campaniaCultivo"
                />
              </div>

              <div v-if="tabOption === 'Adversidades'">
                <FichaAdversidades
                  :error="error"
                  :campaniaCultivo="campaniaCultivo"
                />
              </div>

              <div v-if="tabOption === 'Plagas'">
                <FichaPlagas
                  :error="error"
                  :campaniaCultivo="campaniaCultivo"
                />
              </div>

              <!--               <div v-if="tabOption === 'Lluvia'">
                <FichaLluvia
                  :error="error"
                  :campaniaCultivo="campaniaCultivo"
                  @submit="submitEditLluvia"
                />
              </div> -->

              <div v-if="tabOption === 'Rinde'">
                <FichaRinde
                  :error="error"
                  :campaniaCultivo="campaniaCultivo"
                  @submit="submitEditRinde"
                />
              </div>
            </ion-card-content>
          </ion-card>
          <ion-button
            @click="volver"
            class="ion-margin-top ion-float-end margen9pxLados whiteFontColor"
            color="primary"
          >
            Volver
          </ion-button>
        </div>

        <ion-modal
          id="modalCampania"
          :is-open="modalEditCampania"
          @didDismiss="modalEditCampania = false"
          :backdropDismiss="false"
        >
          <CampaniaForm
            :lote="lote"
            :error="error"
            :persona="persona"
            :campaniaCultivo="campaniaCultivo"
            @cancel="modalEditCampania = false"
            @submit="submitCampaniaCultivo"
            class="modal-wrapper"
          />
        </ion-modal>

        <ion-modal
          id="modalLote"
          :is-open="modalEditLote"
          @didDismiss="modalEditLote = false"
          :backdropDismiss="false"
        >
          <LoteForm
            :lote="lote"
            :error="error"
            :persona="persona"
            @cancel="modalEditLote = false"
            @submit="submitEditLote"
            class="modal-wrapper"
          />
        </ion-modal>

        <br />
        <br />
        <br />
        <br />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  IonButton,
  IonContent,
  IonPage,
  IonImg,
  IonModal,
  IonRow,
  IonGrid,
  IonCol,
  IonCard,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonText,
  alertController,
  IonTabs,
  IonTabBar,
  IonTabButton
} from "@ionic/vue";
//import http from "@/api/http";
import CampaniaForm from "../../components/CampaniaForm.vue";
import LoteForm from "../../components/LoteForm.vue";
import FichaGeneralCampCultivo from "../../components/FichaGeneralCampCultivo.vue";
import FichaActividades from "../../components/FichaActividades.vue";
import FichaAdversidades from "../../components/FichaAdversidades.vue";
import FichaRinde from "../../components/FichaRinde.vue";
import FichaPlagas from "../../components/FichaPlagas.vue";
// import FichaLluvia from "../../components/FichaLluvia.vue";
import {
  CampaniaCultivos,
  Lotes,
  Persona,
  Agua,
  Suelo,
  Rinde,
  Lluvias,
  Establecimientos
} from "@/api";
import {
  pencilOutline,
  caretDownOutline,
  trashOutline,
  reloadOutline
} from "ionicons/icons";

export default {
  components: {
    IonButton,
    IonContent,
    IonLabel,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonCard,
    IonCardContent,
    IonIcon,
    IonModal,
    IonImg,
    IonPage,
    CampaniaForm,
    LoteForm,
    FichaGeneralCampCultivo,
    IonTabs,
    IonTabBar,
    IonTabButton,
    FichaActividades,
    FichaRinde,
    FichaAdversidades,
    FichaPlagas
    // FichaLluvia
  },
  data: () => ({
    modalEditCampania: false,
    modalEditLote: false,
    success: null,
    hideCampaniaDiv: false,
    tabOption: "",
    campaniaCultivo: {},
    establecimiento: {},
    establecimientosRealesCount: 0,
    productor: {},
    campania: {},
    cultivo: {},
    variedadCultivo: {},
    verMas: false,
    error: "",
    lote: {},
    nombreLoteCiudad: "",
    hectareasLote: "",
    nombreEstablecimientoLote: "",
    nombreCampania: "",
    cultivoVariedadCampania: "",
    icons: {
      edit: pencilOutline,
      caretDown: caretDownOutline,
      trash: trashOutline,
      reload: reloadOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    idLote() {
      return this.$route.params.idLote;
    },
    idProductor() {
      return this.$route.params.idProductor;
    },
    persona() {
      if (this.isAdmin && this.idProductor) {
        if (this.productor && this.productor._id) {
          return this.productor;
        }
        return {};
      }
      return this.user;
    },
    lastTab() {
      if (this.$route.params.tab === "Ad") {
        return "Adversidades";
      } else if (this.$route.params.tab === "Ac") {
        return "Actividades";
      }
      return "Actividades";
    },
    txtVariedad() {
      return this.variedadCultivo ? this.variedadCultivo.variedad : "Otra";
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
    await this.existsCampaniaCultivo();
    this.$nextTick(() => {
      this.tabOption = this.lastTab;
    });
  },

  watch: {
    idLote() {
      this.cleaner();
      this.dataSync();
    },
    async idProductor() {
      this.cleaner();
      await this.dataSync();
    }
  },

  methods: {
    ...mapActions(["getMe"]),
    volver() {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-lotes-productor",
          params: { idProductor: this.idProductor }
        });
      } else {
        this.$router.push({
          name: "persona-lotes"
        });
      }
    },

    async dataSync() {
      if (this.idProductor && this.isAdmin) {
        this.productor = await this.getProductor(this.idProductor);
      }
      this.lote = await this.getLote();

      const campaniascultivos = await this.getCampaniaCultivo();
      if (this.$route.query.campCult) {
        const campCultElegida = campaniascultivos.find(
          cc => cc._id === this.$route.query.campCult
        );
        this.campaniaCultivo = campCultElegida
          ? campCultElegida
          : campaniascultivos[0];
      } else {
        const campCultFav = campaniascultivos.find(
          cc => cc.favorito && cc.favorito === true
        );
        this.campaniaCultivo = campCultFav ? campCultFav : campaniascultivos[0];
      }

      if (this.lote.establecimiento) {
        this.establecimiento = await this.getEstablecimiento();
      } else {
        this.establecimiento = { id: "0", nombre: "-" };
      }

      this.getEstablecimientosRealesCount();
      if (this.campaniaCultivo) {
        this.campania = this.campaniaCultivo.campania || "";
        this.cultivo = this.campaniaCultivo.cultivo || "";
        this.variedadCultivo = this.campaniaCultivo.variedadCultivo || "";
      }

      this.nombreLoteCiudad =
        this.lote.nombre + " - " + this.lote.localidad || "";
      this.hectareasLote = this.lote.hectareas || "";
      this.nombreEstablecimientoLote = this.establecimiento.nombre || "";
      this.nombreCampania = this.campania.nombre || "";
      this.cultivoVariedadCampania =
        this.cultivo.nombre + " ( " + this.txtVariedad + " )" || "";
    },

    async getProductor(idProductor) {
      const { data, error } = await Persona.getBy_id(idProductor);
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Productor.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    cleaner() {
      this.hideCampaniaDiv = false;
      this.lote = {};
      this.establecimientosRealesCount = 0;
      this.establecimiento = {};
      this.campaniaCultivo = {};
      this.campania = {};
      this.cultivo = {};
      this.variedadCultivo = {};
      this.nombreLoteCiudad = "";
      this.hectareasLote = "";
      this.nombreEstablecimientoLote = "";
      this.nombreCampania = "";
      this.cultivoVariedadCampania = " ";
    },

    async existsCampaniaCultivo() {
      if (this.campaniaCultivo) {
        this.hideCampaniaDiv = true;
      }
    },

    async getLote() {
      const { data, error } = await Lotes.get({
        query: { _id: this.$route.params.idLote },
        populate: JSON.stringify([
          { path: "establecimiento" },
          {
            path: "establecimiento",
            populate: "regional"
          }
        ])
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lote",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data[0];
    },

    async getEstablecimiento() {
      const { data, error } = await Establecimientos.getBy_id(
        this.lote.establecimiento._id
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo establecimiento",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getCampaniaCultivo() {
      const { data, error } = await CampaniaCultivos.get({
        query: { lote: this.lote._id },
        populate:
          "campania, cultivo, variedadCultivo, semillero, cultivoAntecesor",
        sort: { createdAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo campaña - cultivo",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async editLote() {
      this.modalEditLote = true;
    },

    async getEstablecimientosRealesCount() {
      const pers = this.persona;
      if (!pers._id) {
        return;
      }
      const query = { persona: pers._id, deleted: false };

      const { data, error } = await Establecimientos.get({
        query: query,
        sort: { updatedAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Establecimientos.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      this.establecimientosRealesCount = data.length;
    },

    async submitEditLote({ lote }) {
      this.error = "";
      const { /*data,*/ error } = await Lotes.save(lote);
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        const alert = await alertController.create({
          header: "Lotes",
          message: lote._id ? "Lote Actualizado" : "Lote Creado",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                window.location.reload();
              }
            }
          ]
        });
        this.modalEdit = false;
        alert.present();
      }
    },

    async deleteLote() {
      const alert = await alertController.create({
        header: "Lotes",
        message: "¿Confirma Desactivación del Lote?",
        buttons: [
          {
            text: "Si",
            handler: async () => {
              const { error } = await Lotes.delete(this.lote);
              if (!error) {
                const alert = await alertController.create({
                  header: "Lotes",
                  message: "Lote Desactivado",
                  buttons: [
                    {
                      text: "Aceptar",
                      handler: () => {
                        this.volver();
                      }
                    }
                  ]
                });
                alert.present();
              }
            }
          },
          {
            text: "No"
          }
        ]
      });
      alert.present();
    },

    goToCampanias() {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({ name: "admin-campanias-lote" });
      } else {
        this.$router.push({ name: "lote-campanias" });
      }
    },

    async submitCampaniaCultivo({ campaniaCultivo, lote }) {
      this.error = "";
      const { /*data,*/ error } = await CampaniaCultivos.save(campaniaCultivo);
      if (error) {
        this.error = error;
      } else {
        const { error2 } = await Lotes.save(lote);
        if (error2) {
          this.error = error2;
        } else {
          this.success = true;
          const alert = await alertController.create({
            header: "Campaña - Cultivo",
            message: campaniaCultivo._id
              ? "Campaña - Cultivo Actualizada"
              : "Campaña - Cultivo Creada",
            buttons: [
              {
                text: "Aceptar",
                handler: () => {
                  //this.$router.push({ name: "productor-lotes" });
                  window.location.reload();
                }
              }
            ]
          });
          this.modalEditCampania = false;
          alert.present();
        }
      }
    },

    async submitEditAgua({ agua }) {
      this.error = "";
      const { /*data,*/ error } = await Agua.save(agua);
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        const alert = await alertController.create({
          header: "Agua",
          message: "Datos del Agua Actualizados",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                this.dataSync();
              }
            }
          ]
        });
        alert.present();
      }
    },

    async submitEditSuelo({ suelo }) {
      this.error = "";
      const { /*data,*/ error } = await Suelo.save(suelo);
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        const alert = await alertController.create({
          header: "Suelo",
          message: "Datos del Suelo Actualizados",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                this.dataSync();
              }
            }
          ]
        });
        alert.present();
      }
    },

    async submitEditRinde({ rinde }) {
      this.error = "";
      const { /*data,*/ error } = await Rinde.save(rinde);
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        const alert = await alertController.create({
          header: "Rinde",
          message: "Datos de Rinde Actualizados",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                this.dataSync();
              }
            }
          ]
        });
        alert.present();
      }
    },
    async submitEditLluvia({ lluvia }) {
      this.error = "";
      const { /*data,*/ error } = await Lluvias.save(lluvia);
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        const alert = await alertController.create({
          header: "Lluvia",
          message: "Datos de Lluvia Actualizados",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                this.dataSync();
              }
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
  ion-tab-bar {
    overflow-x: hidden !important;
    padding-bottom: 0;
  }
}

.whiteFontColor {
  --ion-color-constrast: #ffffff;
}

.negrita {
  font-size: 175%;
  margin-left: 0px;
  font-weight: bold;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.smallFont {
  font-size: small;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

.itemColor {
  --background: rgba(var(--ion-color-primary-rgb), 0.6);
  --border-color: rgba(var(--ion-color-primary-rgb), 0.6);
}

.margen8pxArriba {
  margin-top: 8px;
}

ion-tab-button {
  --ripple-color: var(--ion-color-primary);
  --color: var(--ion-color-dark);
  --color-selected: var(--ion-color-primary);
}

ion-tab-button.tab-selected {
  --background: var(--ion-color-primary) !important;
  --ripple-color: var(--ion-color-light);
  --color-selected: var(--ion-color-dark);
}

ion-tab-bar {
  overflow-x: scroll;
  justify-content: left;
}
</style>
