<template>
  <ion-grid fixed>
    <ion-row class="ion-align-items-center ion-margin-top">
      <ion-text style="font-weight:bold; font-size:20px;">Rinde</ion-text>
      <ion-button fill="clear" size="small" @click="modalEditHandler">
        <ion-icon :md="icons.edit" :ios="icons.edit" color="dark"></ion-icon>
      </ion-button>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Rendimiento: " }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="rendimiento"
      >
        {{ rendimiento + " kg/ha" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Humedad: " }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="humedad"
      >
        {{ humedad + " %" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Proteína: " }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="proteina"
      >
        {{ proteina + " %" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Calibre: " }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="calibre"
      >
        {{ calibre + " %" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Gluten: " }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="gluten"
      >
        {{ gluten + " %" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Peso Hectolítrico: " }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="pesoHectolitrico"
      >
        {{ pesoHectolitrico + " kg/100L" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Materia Grasa: " }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="materiaGrasa"
      >
        {{ materiaGrasa + " %" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Ácido Oleico: " }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="acidoOleico"
      >
        {{ acidoOleico + " %" }}
      </ion-text>
    </ion-row>
  </ion-grid>

  <ion-modal
    :is-open="modalEdit"
    @didDismiss="modalEdit = false"
    :backdropDismiss="false"
    :showBackdrop="true"
  >
    <ion-content :scroll-events="true">
      <ion-card>
        <ion-card-title
          class="ion-margin-top"
          style="margin-left:16px; font-weight: bold;"
        >
          Rinde
        </ion-card-title>
        <ion-card-content>
          <ion-item>
            <ion-label position="floating">Rendimiento</ion-label>
            <ion-input
              v-model="modal.rendimiento"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="kg/ha"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Humedad</ion-label>
            <ion-input
              v-model="modal.humedad"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="porcentaje(%)"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Proteína</ion-label>
            <ion-input
              v-model="modal.proteina"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="porcentaje(%)"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Calibre</ion-label>
            <ion-input
              v-model="modal.calibre"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="porcentaje(%)"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Gluten</ion-label>
            <ion-input
              v-model="modal.gluten"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="porcentaje(%)"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Peso Hectolítrico</ion-label>
            <ion-input
              v-model="modal.pesoHectolitrico"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="kg/100L"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Materia Grasa</ion-label>
            <ion-input
              v-model="modal.materiaGrasa"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="porcentaje(%)"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Ácido Oleico</ion-label>
            <ion-input
              v-model="modal.acidoOleico"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="porcentaje(%)"
            ></ion-input>
          </ion-item>
          <ion-row class="ion-float-right">
            <ion-col>
              <ion-button
                fill="outline"
                class="ion-margin-top"
                color="medium"
                @click="cancel"
              >
                Cancelar
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                class="ion-float-right ion-margin-top"
                @click="submit"
              >
                Aceptar
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  //IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonText,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonGrid,
  IonInput,
  IonIcon,
  alertController,
  IonButton,
  IonModal
} from "@ionic/vue";
import { Rinde } from "@/api";
import { mapState, mapActions } from "vuex";
import { pencilOutline } from "ionicons/icons";
//import isEmail from "validator/lib/isEmail";

export default {
  components: {
    //IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonText,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonGrid,
    IonIcon,
    IonButton,
    IonModal
    //VField: V.Field,
    //VErrorMessage: V.ErrorMessage
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) }
  },

  data: () => ({
    rinde: {},
    modalEdit: false,

    rendimiento: "",
    humedad: "",
    proteina: "",
    calibre: "",
    gluten: "",
    pesoHectolitrico: "",
    materiaGrasa: "",
    acidoOleico: "",

    modal: {
      rendimiento: "",
      humedad: "",
      proteina: "",
      calibre: "",
      gluten: "",
      pesoHectolitrico: "",
      materiaGrasa: "",
      acidoOleico: ""
    },

    icons: {
      edit: pencilOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    idLote() {
      return this.$route.params.idLote;
    }
  },

  watch: {
    async campaniaCultivo() {
      this.modalCleaner();
      await this.dataSync();
    },
    async idLote() {
      this.modalCleaner();
      await this.dataSync();
    },
    async idProductor() {
      await this.dataSync();
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
  },

  methods: {
    ...mapActions(["getMe"]),

    cancel() {
      this.modalEdit = false;
      this.modalCleaner();
    },

    async dataSync() {
      this.rinde = await this.getRinde();
      if (this.rinde && this.rinde._id) {
        this.rendimiento = this.rinde.rendimiento;
        this.humedad = this.rinde.humedad;
        this.proteina = this.rinde.proteina;
        this.calibre = this.rinde.calibre;
        this.gluten = this.rinde.gluten;
        this.pesoHectolitrico = this.rinde.pesoHectolitrico;
        this.materiaGrasa = this.rinde.materiaGrasa;
        this.acidoOleico = this.rinde.acidoOleico;
      } else {
        this.rendimiento = "";
        this.humedad = "";
        this.proteina = "";
        this.calibre = "";
        this.gluten = "";
        this.pesoHectolitrico = "";
        this.materiaGrasa = "";
        this.acidoOleico = "";
      }
    },

    async getRinde() {
      const { data, error } = await Rinde.get({
        query: {
          campaniaCultivo: this.campaniaCultivo._id
        },
        sort: { createdAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo datos de Rinde.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      if (data.length) {
        return data[0];
      }
      return data;
    },

    fillFieldsModal() {
      this.modal.rendimiento = this.rendimiento;
      this.modal.humedad = this.humedad;
      this.modal.proteina = this.proteina;
      this.modal.calibre = this.calibre;
      this.modal.gluten = this.gluten;
      this.modal.pesoHectolitrico = this.pesoHectolitrico;
      this.modal.materiaGrasa = this.materiaGrasa;
      this.modal.acidoOleico = this.acidoOleico;
    },

    modalCleaner() {
      this.modal.rendimiento = "";
      this.modal.humedad = "";
      this.modal.proteina = "";
      this.modal.calibre = "";
      this.modal.gluten = "";
      this.modal.pesoHectolitrico = "";
      this.modal.materiaGrasa = "";
      this.modal.acidoOleico = "";
    },

    async submit() {
      this.$emit("update:success", false);
      let error = "";
      if (this.modal.rendimiento) {
        if (isNaN(this.modal.rendimiento)) {
          error +=
            "<li>El campo <b>Rendimiento</b> es numérico." + `<br/></li>`;
        } else if (this.modal.rendimiento < 0) {
          error +=
            "<li>El campo <b>Rendimiento</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (this.modal.humedad) {
        if (isNaN(this.modal.humedad)) {
          error += "<li>El campo <b>Humedad</b> es numérico." + `<br/></li>`;
        } else if (this.modal.humedad < 0 || this.modal.humedad > 100) {
          error +=
            "<li>El campo <b>Humedad</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.proteina) {
        if (isNaN(this.modal.proteina)) {
          error += "<li>El campo <b>Proteína</b> es numérico." + `<br/></li>`;
        } else if (this.modal.proteina < 0 || this.modal.proteina > 100) {
          error +=
            "<li>El campo <b>Proteína</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.calibre) {
        if (isNaN(this.modal.calibre)) {
          error += "<li>El campo <b>Calibre</b> es numérico." + `<br/></li>`;
        } else if (this.modal.calibre < 0 || this.modal.calibre > 100) {
          error +=
            "<li>El campo <b>Calibre</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.gluten) {
        if (isNaN(this.modal.gluten)) {
          error += "<li>El campo <b>Gluten</b> es numérico." + `<br/></li>`;
        } else if (this.modal.gluten < 0 || this.modal.gluten > 100) {
          error +=
            "<li>El campo <b>Gluten</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.pesoHectolitrico) {
        if (isNaN(this.modal.pesoHectolitrico)) {
          error +=
            "<li>El campo <b>Peso Hectolítrico</b> es numérico." + `<br/></li>`;
        } else if (this.modal.pesoHectolitrico < 0) {
          error +=
            "<li>El campo <b>Peso Hectolítrico</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (this.modal.materiaGrasa) {
        if (isNaN(this.modal.materiaGrasa)) {
          error +=
            "<li>El campo <b>Materia Grasa</b> es numérico." + `<br/></li>`;
        } else if (
          this.modal.materiaGrasa < 0 ||
          this.modal.materiaGrasa > 100
        ) {
          error +=
            "<li>El campo <b>Materia Grasa</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.acidoOleico) {
        if (isNaN(this.modal.acidoOleico)) {
          error +=
            "<li>El campo <b>Acido Oleico</b> es numérico." + `<br/></li>`;
        } else if (this.modal.acidoOleico < 0 || this.modal.acidoOleico > 100) {
          error +=
            "<li>El campo <b>Acido Oleico</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (!error) {
        this.$emit("submit", {
          rinde: {
            _id: this.rinde._id,
            rendimiento: this.modal.rendimiento,
            humedad: this.modal.humedad,
            proteina: this.modal.proteina,
            calibre: this.modal.calibre,
            gluten: this.modal.gluten,
            pesoHectolitrico: this.modal.pesoHectolitrico,
            materiaGrasa: this.modal.materiaGrasa,
            acidoOleico: this.modal.acidoOleico,
            campaniaCultivo: this.campaniaCultivo
          }
        });
        this.modalEdit = false;
        this.modalCleaner();
      } else {
        const alert = await alertController.create({
          header: "Error al editar datos de Rinde",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    modalEditHandler() {
      this.fillFieldsModal();
      this.modalEdit = true;
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

ion-item {
  --pading-start: 8px;
}

ion-content {
  --background: transparent;
}
</style>
