<template>
  <ion-content :scroll-events="true">
    <ion-card>
      <ion-card-content>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-label
              position="floating"
              style="font-weight: bold; font-size: 16px;"
            >
              Plaga
            </ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-thumbnail>
              <img
                src="assets/icon/plagas/icoPlgMaleza.png"
                v-if="plaga.tipoPlaga === 'Maleza'"
              />
              <img
                src="assets/icon/plagas/icoPlgInsecto.png"
                v-if="plaga.tipoPlaga === 'Insecto'"
              />
              <img
                src="assets/icon/plagas/icoPlgEnferm.png"
                v-if="plaga.tipoPlaga === 'Enfermedad'"
                style="margin-left: 20px"
              />
            </ion-thumbnail>
            <ion-label v-if="plaga.tipoPlaga === 'Maleza'">Maleza</ion-label>
            <ion-label v-if="plaga.tipoPlaga === 'Insecto'">Insecto</ion-label>
            <ion-label v-if="plaga.tipoPlaga === 'Enfermedad'">
              Enfermedad
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <ion-label
            color="dark"
            style="font-size: 20px; font-weight: bold; margin-left: 5px;"
          >
            {{ plaga.nombrePlaga }}
          </ion-label>
        </ion-row>
        <ion-list-header class="ion-margin-top">
          Pulverizaciones
        </ion-list-header>
        <ion-list lines="full" v-for="p in pulverizaciones" :key="p">
          <ion-item class="itemBorder">
            <ion-grid fixed>
              <ion-row>
                <ion-col size="auto">
                  <ion-thumbnail>
                    <ion-img src="assets/icon/actividades/icoPulveriza.png" />
                  </ion-thumbnail>
                </ion-col>
                <ion-col>
                  <ion-row>
                    <b>
                      {{ formatDate(p.fecha) }}
                    </b>
                  </ion-row>
                  <ion-row style="font-size:75%; white-space: normal;">
                    {{ getListaProductos(p.productos) }}
                  </ion-row>
                </ion-col>
                <ion-col size="auto" class="ion-float-end">
                  <ion-img
                    src="assets/icon/plagas/icoPlgFacil.png"
                    style="height:50%"
                    v-if="p.dificultad === 'Facil'"
                  />
                  <ion-img
                    src="assets/icon/plagas/icoPlgMedio.png"
                    style="height:50%"
                    v-if="p.dificultad === 'Medio'"
                  />
                  <ion-img
                    src="assets/icon/plagas/icoPlgDificil.png"
                    style="height:50%"
                    v-if="p.dificultad === 'Dificil'"
                  />
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-list>
        <ion-row class="ion-float-right">
          <ion-col size="auto">
            <ion-button
              class="ion-float-right ion-margin-top ion-margin-bottom"
              @click="close"
            >
              Volver
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonThumbnail,
  IonGrid,
  IonRow,
  IonCol,
  alertController,
  IonLabel,
  IonList,
  IonItem,
  IonListHeader,
  IonButton
} from "@ionic/vue";
import http from "@/api/http";
import format from "@/utils/dateFormat";
// import { Malezas, Insectos, Enfermedades } from "@/api";

export default {
  components: {
    IonContent,
    IonCard,
    IonCardContent,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonButton
  },

  props: {
    error: { type: String, default: "" },
    plaga: { type: Object, default: () => ({}) },
    campaniaCultivo: { type: Object, default: () => ({}) }
  },

  data: () => ({ pulverizaciones: [] }),

  async mounted() {
    await this.dataSync();
  },

  methods: {
    async dataSync() {
      this.pulverizaciones = await this.getPulverizaciones();
    },

    async getPulverizaciones() {
      const { data, error } = await http.get(
        "/api/get-pulverizaciones-de-plaga",
        {
          params: {
            campaniaCultivo: this.campaniaCultivo.id,
            plaga: this.plaga
          }
        }
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo las Pulverizaciones de la Plaga.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      return data;
    },

    formatDate(value) {
      if (!value) return "";
      return format(value, "dd/MM/yyyy");
    },

    getListaProductos(productos) {
      if (!productos) return "";
      return productos.map(p => p.producto.nombre).join(", ");
    },

    cleaner() {
      this.pulverizaciones = [];
    },

    close() {
      this.cleaner();
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
ion-item {
  --pading-start: 8px;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

ion-content {
  --background: transparent;
}
</style>
